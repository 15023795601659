/* eslint-disable */
import React, { useEffect } from 'react'
import Helmet from '../components/Helmet'

import { graphql } from 'gatsby'

import marriedCoupleBeach from '../images/heroes/husband-and-wife-walking-on-beach-with-bicycles.jpg'
import elderlyWomanTablet from '../images/people/elderly-woman-using-tablet.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import aetna from '../images/brand-logos/aetna.svg'
import cigna from '../images/brand-logos/cigna.svg'
import regence from '../images/brand-logos/regence.svg'
import united from '../images/brand-logos/united-healthcare.svg'
import wellcare from '../images/brand-logos/wellcare.svg'
import mutual from '../images/brand-logos/mutual-omaha.svg'

import sslSecure from '../images/icons/ssl-secure.svg'
import trustPilot from '../images/icons/trustpilot-stars.svg'

import ResponsiveGrid from '../components/GridLayouts/ResponsiveGrid'

import {
  AvailabilityForm,
  Billboard,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  SplitContent,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import headerData from '../data/headerData.js'
import isCallCenterOpen from '../../../../utils/isOpen'
import GlobalBanner from '../components/GlobalBanner'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-indexlp']

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const trustSymbols = [
    { src: sslSecure, alt: 'SSL Secure icon' },
    {
      src: trustPilot,
      alt: 'Trustpilot review stars',
      href: 'https://www.trustpilot.com/review/clearlinkinsurance.com',
      target: '_blank',
      className: 'trust-pilot-link',
    },
  ]

  const { rotatedNumber, requestId } = useMapi()

  useEffect(() => {
    isCallCenterOpen()
  }, [])

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title: 'Healthcareplans.com | 833-316-3576| Medicare Insurance',
          description:
            "We speak Medicare so you don't have to. Let us help you find the right plan for your needs. Whether it's a Medicare Advantage, Medicare Part D, or Dual Special Needs Plan; we're here to answer your questions and get you covered today | 833-316-3576",
          canonical: 'https://healthcareplans.com/',
          robots: 'follow,index',
        },
        path: '/',
        promoCode: '169379',
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Healthcareplans.com | 833-316-3576| Medicare Insurance',
    defaultRobots: 'follow,index',
    main: (
      <>
        <Helmet />
        <div className="hero-content">
          <div className="wrapper">
            <Billboard
              variant="full"
              textColor="Dark"
              image={
                <img
                  src={marriedCoupleBeach}
                  alt="elderly married couple walking on beach with bicycles and smiling"
                  className="married-couple-beach-hero"
                />
              }
              mobileImage={
                <img
                  src={marriedCoupleBeach}
                  alt="elderly married couple walking on beach with bicycles and smiling"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Compare Medicare Plans Online
                  </Typography>

                  <Typography variant="h4">
                    It’s fast, it’s easy—and most importantly, it’s secure. If
                    you're turning 65 or have a qualifying life event, enter
                    your ZIP code to see plans available in your area.
                  </Typography>

                  {isCallCenterOpen() ? (
                    <LinkButton
                      variant="feature"
                      to={`tel:${rotatedNumber}`}
                      color="primary"
                      className=""
                      requestId={requestId}
                    >
                      Call Now {rotatedNumber}
                    </LinkButton>
                  ) : (
                    <AvailabilityForm
                      placeholderText="Zip Code"
                      buttonText="View Pricing"
                      buttonColor="primary"
                      butttonBackgroundColor="black"
                      className="avail-form"
                    />
                  )}
                </>
              }
            />
          </div>
        </div>

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor="light"
          customLink={null}
          className="brand-logos"
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <VariableContent
          mainContent={
            <>
              <Typography variant="h2">
                Turning 65 or qualifying for a Special Enrollment Period? Use
                Healthcareplans.com to find a Medicare plan on your terms.
              </Typography>
            </>
          }
          alignMainContent="left"
          style={{
            color: '#1b0b54',
          }}
        >
          <Stack>
            <Columns>
              <Column>
                <StaticImage
                  src="../images/illustrations/location.svg"
                  alt="location icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Easy</Typography>
                <Typography variant="body">
                  If you’re eligible for a new Medicare plan, all you have to do
                  to compare plans in your area is input your ZIP code. Add
                  preferred providers and prescribed medications for the best
                  match.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../images/illustrations/computer.svg"
                  alt="computer icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Convenient</Typography>
                <Typography variant="body">
                  When you browse online, skip the hold music – and the long
                  conversations where you have to repeat your information.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../images/illustrations/medicare-card.svg"
                  alt="medicare card icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Efficient</Typography>
                <Typography variant="body">
                  Compare Medicare Advantage and Part D plans from top insurance
                  providers in your area, all in one place.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../images/illustrations/medal.svg"
                  alt="medal icon"
                  layout="fixed"
                  width={64}
                  height={64}
                  className="product-icons"
                />
                <Typography variant="h5">Trusted</Typography>
                <Typography variant="body">
                  Even as you complete the process online, our licensed agents
                  are on call and available to assist you.
                </Typography>
              </Column>
            </Columns>
          </Stack>
          <LinkButton
            variant="feature"
            to="https://www.sunfirematrix.com/app/consumer/clearlink/#/"
            color="primary"
            className="margin-x-auto"
            requestId={requestId}
          >
            Get Started
          </LinkButton>
        </VariableContent>

        <GlobalBanner />

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor={null}
          customLink={null}
          className="brand-logos bg-lightgray"
        >
          <ResponsiveGrid
            images={trustSymbols}
            numberOfColumns={2}
            numberOfMobileColumns={1}
            constraint="small"
            columnGap="10px"
          />
        </VariableContent>

        <SplitContent
          backgroundColor="white"
          alignImageToBottom={false}
          style={{
            color: '#1b0b54',
          }}
          image={
            <img
              src={elderlyWomanTablet}
              alt="an elderly woman uses a tablet at a desk and smiles"
            />
          }
          mobileImage={
            <img
              src={elderlyWomanTablet}
              alt="an elderly woman uses a tablet at a desk and smiles"
            />
          }
          mainContent={
            <>
              <Typography variant="h2">
                How we assist you on your Medicare journey
              </Typography>
              <List className="text-center-on-small">
                <ListItem>
                  Since 2017, we’ve helped more than 100,000 people navigate the
                  Medicare plan process
                </ListItem>
                <ListItem>
                  Working with the top insurance providers across the nation, we
                  offer a variety of Medicare Advantage and Medicare Part D plan
                  options
                </ListItem>
                <ListItem>
                  With more than a decade as an insurance broker, you can trust
                  our Medicare experts
                </ListItem>
                <ListItem>
                  More than 300 agents, licensed across the US, are available
                  for additional support and customer service
                </ListItem>
              </List>
            </>
          }
        />
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
